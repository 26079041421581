import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GraphImg from "graphcms-image";

const TeamSection = () => {
	const data = useStaticQuery(graphql`
	  query {
			dnhCms {
				teamMembers(where: {status: PUBLISHED}) {
					id
					image {
						handle
						width
						height
						fileName
					}
					name
					position
				}
			}
		}
	`)

	const shuffled = data.dnhCms.teamMembers.sort(() => 0.5 - Math.random());
	let selected = shuffled.slice(0, 8);

	return (
		<div className="team-wrap container">
			{selected.map(member => (
				<figure className="team__member" key={member.id}>
					<GraphImg image={member.image} fit="scale" backgroundColor={true} withWebp={true} alt={member.name} />
					<figcaption>
						<h4 className="team__member--name">{member.name}</h4>
						<span className="team__member--position">{member.position}</span>
					</figcaption>
				</figure>
			))}
		</div>
	)
}

export default TeamSection
